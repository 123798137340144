import { createRouter, createWebHistory } from 'vue-router'
import helloWorld from '../components/HelloWorld.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: helloWorld
  },
  {
    path: '/previewPdf',
    name: 'previewPdf',
    component: () => import('../components/previewPdf')
  },
  {
    path: '/previewPdfDouble',
    name: 'previewPdfDouble',
    component: () => import('../components/previewPdfDouble')
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
