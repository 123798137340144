import { axios } from "./index.js";

//获取书籍列表
export function queryBookList(parameter) {
  return axios({
    url: "/queryBookList",
    method: "get",
    params: parameter,
  });
}

//上传文件
export function sysFileInfoUpload(parameter) {
  return axios({
    url: "/upload",
    method: "post",
    data: parameter,
  });
}

//获取书籍详情
export function getBookDetail(parameter) {
  return axios({
    url: `/getFile`,
    method: "get",
    params: parameter,
  });
}

//获取书籍信息
export function getFileInfo(parameter) {
  return axios({
    url: `/getFileInfo`,
    method: "get",
    params: parameter,
  });
}

//获取书籍封面
export function getImage(parameter) {
  return axios({
    url: `/getImage`,
    method: "get",
    params: parameter,
    // responseType: "arraybuffer",
  });
}
//获取书籍封面
export function deleteBook(parameter) {
  return axios({
    url: `/deleteBook`,
    method: "delete",
    params: parameter,
    // responseType: "arraybuffer",
  });
}
