<template>
  <div id="book-app">
    <div v-if="screenWidth < 700" class="little-tab">
      <div v-if="showTitle" class="book-title">半波文化宣传书刊</div>
      <div class="tab-bar">
        <div class="left">
          <el-image
            style="width: 30px; height: 30px"
            :src="require('../assets/logo.png')"
            fit="cover"
          />
        </div>

        <div class="middle">
          <el-input
            v-model="input2"
            style="width: 100%"
            placeholder="请输入书名"
            suffix-icon="Search"
            @blur="getBooksList"
          />
        </div>
        <div class="right">
          <el-image
            @click="loginDialog = true"
            style="width: 30px; height: 30px"
            :src="require('../assets/person.png')"
            fit="cover"
          />
          <el-image
            style="width: 30px; height: 30px; margin-left: 10px"
            @click="getQRCode"
            :src="require('../assets/share.png')"
            fit="cover"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="tab-bar-relative">
        <div class="left">
          <el-image
            style="width: 30px; height: 30px"
            :src="require('../assets/logo.png')"
            fit="cover"
          />
        </div>
        <div v-if="showTitle" class="book-title">半波文化宣传书刊</div>
        <div class="middle">
          <el-input
            v-model="input2"
            style="width: 100%"
            placeholder="请输入书名"
            suffix-icon="Search"
            @blur="getBooksList"
          />
        </div>
        <div class="right">
          <el-image
            @click="loginDialog = true"
            style="width: 30px; height: 30px"
            :src="require('../assets/person.png')"
            fit="cover"
          />
          <el-image
            style="width: 30px; height: 30px; margin-left: 10px"
            @click="getQRCode"
            :src="require('../assets/share.png')"
            fit="cover"
          />
        </div>
      </div>
    </div>
    <div class="bookshelf">
      <div class="book" v-for="(book, index) in books" :key="index">
        <div class="upload-div" v-if="index == 0 && permissionBtn" @click="dialogVisible = true">
          <el-icon size="20">
            <UploadFilled />
          </el-icon>上传文章
        </div>
        <div v-else style="position: relative; width: 100%; height: 100%" @click="coverEvent(book)">
          <el-image style="width: 100%; height: 100%" :src="book.imgUrl" fit="cover">
            <template #error>
              <div class="image-slot">暂无图片</div>
            </template>
          </el-image>
          <div v-if="book.showMask" class="bookMask">
            <el-icon @click.stop="previewBook(book.id, book.filename)">
              <View />
            </el-icon>
            <el-icon @click.stop="deleteBook(book.id)" v-if="permissionBtn">
              <Delete />
            </el-icon>
          </div>
        </div>
      </div>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
    </div>
    <div v-if="books.length == 0">暂无文章</div>
    <el-dialog
      v-model="dialogVisible"
      destroy-on-close
      title="文章上传"
      width="300"
      class="dialog-class"
      align-center
      @closed="closeDialog"
    >
      <div v-if="!showProgress">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline upload-class"
          label-width="100px"
        >
          <el-form-item label="封面：">
            <el-upload
              v-if="showImgbtn"
              v-model:file-list="formInline.imgList"
              :class="showImgbtn ? `avatar-uploader` : `hiddenBtn`"
              action
              :auto-upload="false"
              @change="
              (file) => {
                beforeUpload(file, 'img');
              }
            "
            >
              <el-button type="primary">上传封面</el-button>
              <template #tip>
                <div class="el-upload__tip">图片大小不超过2M</div>
              </template>
            </el-upload>
            <div v-else>
              <el-icon>
                <Document />
              </el-icon>
              {{ uploadImgData.name }}
            </div>
          </el-form-item>
          <el-form-item label="文章：">
            <el-upload
              v-if="showFilebtn"
              v-model:file-list="formInline.pdfList"
              :class="showFilebtn ? `upload-demo` : `hiddenBtn`"
              action
              :auto-upload="false"
              @change="(file) => beforeUpload(file, 'file')"
              multiple
              :limit="3"
            >
              <el-button type="primary">上传文章</el-button>
            </el-upload>
            <div v-else>
              <el-icon>
                <Document />
              </el-icon>
              {{ uploadFileData.name }}
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div v-else>
        <div>
          <el-progress
            type="dashboard"
            :percentage="progressData"
            :color="colors"
            v-if="progressData||progressData === 0"
          />
        </div>
      </div>
      <template #footer v-if="!showProgress">
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="uploadFile">上传</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog
      v-model="loginDialog"
      destroy-on-close
      title="用户登录"
      width="300"
      class="dialog-class"
      align-center
    >
      <el-form :inline="true" :model="loginForm" class="demo-form-inline" label-width="60px">
        <el-form-item label="账号：">
          <el-input v-model="loginForm.username" placeholder="请输入账号" />
        </el-form-item>
        <el-form-item label="密码：">
          <el-input v-model="loginForm.password" placeholder="请输入密码" />
        </el-form-item>
      </el-form>
      <el-text class="mx-1">提示：普通用户无需登录</el-text>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="loginDialog = false">取消</el-button>
          <el-button type="primary" @click="loginUser">登录</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 分享页面 -->
    <el-drawer v-model="shareDrawer" title="分享方式" direction="btt" :destroy-on-close="true">
      <div
        style="
          display: flex;
          justify-content: space-around;
          align-items: center;
        "
      >
        <div>
          <el-button type="primary" @click="copyLink">点此复制链接</el-button>
        </div>
        <div class="qr-code" ref="qrCodeUrl"></div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
import { shallowRef } from "vue";
import { mapActions } from "vuex";
// import LoadingAnimation from '../loading/loading';
// import '../loading/loading.css'

import {
  queryBookList,
  sysFileInfoUpload,
  getImage,
  deleteBook
} from "../axios/api.js";
import { ElMessage } from "element-plus";

export default {
  data() {
    return {
      books: [],
      input2: "",
      fileList: [],
      imgUrl: "",
      fileData: "",
      oldIndex: null,
      formInline: {
        imgList: [],
        fileList: []
      },
      loginForm: {},
      dialogVisible: false,
      uploadFileData: {},
      uploadImgData: {},
      showImgbtn: true,
      showFilebtn: true,
      loginDialog: false,
      shareDrawer: false,
      ws: "",
      loadingInstance: "",
      progressData: 0,
      showProgress: false,
      colors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 }
      ]
    };
  },
  computed: {
    permissionBtn() {
      return this.$store.getters.getCount;
    },
    showTitle() {
      return this.$store.getters.getModel;
    },
    screenWidth() {
      return window.innerWidth;
    }
  },
  mounted() {
    
    this.getBooksList();
    this.ws = new WebSocket("ws://139.199.176.74:8080");
    // 监听消息事件
    // 监听连接成功事件
    this.ws.onopen = () => {
      console.log("WebSocket 连接已建立");
      // this.connected = true;
    };
    this.ws.onmessage = event => {
      console.log("接收到消息:", event.data);
      // eslint-disable-next-line use-isnan
      this.progressData = isNaN(parseInt(event.data))
        ? 0
        : parseInt(event.data);
      // this.receivedMessage = event.data;
    };
    window.addEventListener("resize", this.addShelves);
  },
  methods: {
    ...mapActions(["increment"]),
    //获取书籍列表
    async getBooksList() {
      const { data, message, success } = await queryBookList({
        filename: this.input2
      });
      if (success) {
        this.books = this.permissionBtn ? [{}, ...data] : [...data];
        this.getCover();
        this.$nextTick(() => {
          this.addShelves();
        });
      } else {
        ElMessage({
          showClose: true,
          message: message,
          type: "error"
        });
      }
    },
    coverEvent(book) {
      book.showMask = !book.showMask;
    },
    async getCover() {
      this.books.forEach(async item => {
        if (item.id) {
          const { image, message, success } = await getImage({ id: item.id });
          item.imgUrl = `data:image/png;base64, ${image}`;
        }
      });
    },
    previewBook(id, filename) {
      console.log(window.innerWidth);
      if (window.innerWidth >= 700) {
        this.$router.push({
          path: "/previewPdfDouble",
          query: { number: id, filename: filename }
        });
      } else {
        this.$router.push({
          path: "/previewPdf",
          query: { number: id, filename: filename }
        });
      }
      // ElMessage({
      //   message:'你好',
      //   type:'success'
      // })
    },
    async deleteBook(id) {
      const { success, message } = await deleteBook({ id: id });
      if (success) {
        ElMessage({
          message: "删除成功",
          type: "success"
        });
        this.getBooksList();
      } else {
        ElMessage({
          message: "删除失败",
          type: "error"
        });
      }
    },
    addShelves() {
      const bookshelf = document.querySelector(".bookshelf");
      const books = document.querySelectorAll(".book");
      const bookshelfWidth = bookshelf.clientWidth;
      let rowWidth = 0;
      let rowIndex = 0;
      books.forEach(book => {
        rowWidth += book.clientWidth;
        if (rowWidth > bookshelfWidth) {
          rowWidth = book.clientWidth;
          rowIndex++;
        }
        const shelf = document.createElement("div");
        shelf.classList.add("shelf");
        shelf.style.top = `${(rowIndex + 1) * (book.clientHeight + 20)}px`; // 20px是间距'
        bookshelf.appendChild(shelf);
      });
    },

    //上传文件接口
    beforeUpload(file, type) {
      const fileType = file.raw.type;
      const fileSize = file.raw.size;
      if (type == "img") {
        if (
          (fileType == "image/jpeg" || fileType == "image/png") &&
          fileSize / 1024 / 1024 < 2
        ) {
          this.showImgbtn = false;
          this.uploadImgData = shallowRef(file);
          console.log(this.uploadImgData);
        } else {
          ElMessage({
            message: "请检查文件格式和大小",
            type: "error"
          });
          this.uploadImgData = {};
          return false;
        }
      } else if (type == "file") {
        if (fileType == "application/pdf") {
          this.showFilebtn = false;
          this.uploadFileData = shallowRef(file);
        } else {
          ElMessage({
            message: "文件格式不支持，文章仅支持上传pdf格式",
            type: "error"
          });
          this.uploadFileData = {};
          return false;
        }
      }
    },
    //点击上传按钮上传文件
    async uploadFile() {
      if (
        Object.keys(this.uploadImgData).length === 0 ||
        Object.keys(this.uploadFileData).length === 0
      ) {
        ElMessage({
          type: "error",
          message: "请上传文件和图片"
        });
        return false;
      }
      const formData = new FormData();
      formData.append("image", this.uploadImgData.raw);
      formData.append("file", this.uploadFileData.raw);
      this.showProgress = true;
      const { success, message } = await sysFileInfoUpload(formData);
      if (success) {
        ElMessage({
          message: "上传成功",
          type: "success"
        });
        setTimeout(() => {
          this.closeDialog();
          this.getBooksList();
        }, 1000);
      } else {
        ElMessage({
          message: "上传失败",
          type: "error"
        });
      }
    },
    //监听上传进度
    setUploadProcess(percent) {
      console.log(percent);
      // this.percent = percent
      // if (this.percent == 100) {
      //   this.percent = 0
      //   this.visiblePercent = false
      //   this.title = ''
      // }
    },
    //关闭上传弹窗
    closeDialog() {
      this.uploadFileData = {};
      this.uploadImgData = {};
      this.showFilebtn = true;
      this.showImgbtn = true;
      this.dialogVisible = false;
      this.showProgress = false;
      this.progressData = 0;
    },
    loginUser() {
      if (
        this.loginForm.username === "superAdmin" &&
        this.loginForm.password === "123456"
      ) {
        this.loginDialog = false;
        ElMessage({
          message: "登录成功",
          type: "success"
        });
        this.$store.dispatch("increment");
        // this.permissionBtn = true;
        this.getBooksList();
      } else {
        ElMessage({
          message: "账号或密码错误，如果是普通用户，无需登录！",
          type: "error"
        });
      }
    },
    getQRCode() {
      this.shareDrawer = true;
      setTimeout(() => {
        new QRCode(this.$refs.qrCodeUrl, {
          text: "http://139.199.176.74/", // 二维码的内容
          width: 150,
          height: 150,
          colorDark: "#000",
          colorLight: "#fff",
          correctLevel: QRCode.CorrectLevel.H
        });
      }, 0);
    },
    copyLink() {
      this.$copyText("http://139.199.176.74/").then(
        e => {
          alert("复制成功");
          console.log(e);
        },
        function(e) {
          alert("复制失败");
          console.log(e);
        }
      );
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.addShelves);
    this.ws.close();
    this.ws.onclose = () => {
      console.log("WebSocket 连接已关闭");
    };
  }
};
</script>

<style lang="less">
#book-app {
  padding: 15px;
}
.bookshelf {
  width: 100%;
  max-height: calc(100vh - 130px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* 左对齐 */
  position: relative; /* 相对定位 */
  padding-bottom: 20px; /* 为书架下方留出空间 */
  overflow-y: scroll;
  overflow-x: hidden;
  > i {
    width: 100px;
    margin: 10px;
  }
  .upload-div {
    display: flex;
    position: relative; /* 相对定位 */
    width: 96px;
    height: 146px;
    background-color: #fff;
    border-radius: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed #999;
  }
}
.book {
  position: relative; /* 相对定位 */
  width: 100px;
  height: 150px;
  margin: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* 隐藏溢出内容 */
}
.book img {
  width: 100%; /* 图片宽度100% */
  height: 100%; /* 图片高度100% */
  object-fit: cover; /* 填充整个空间 */
}
.shelf {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px; /* 书架的高度 */
  background-color: rgb(229, 229, 229); /* 书架的颜色 */
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: perspective(300px) rotateX(50deg);
  transform: perspective(300px) rotateX(50deg);
}
.bookMask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #999999e0;
  z-index: 100;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.tab-bar {
  max-width: 400px;
  margin: 0 auto;
  height: 40px;
  display: flex;
  justify-content: space-around;
  position: relative;
  /* .left {
        width: 20%;
    }
    .middle {
        width: 60%;
    }
    .right {
        width: 10%;
    } */
}
.tab-bar-relative {
  position: relative;
  margin-bottom: 30px;
  height: 50px;
  .left {
    position: absolute;
    left: 20px;
    top: 10px;
  }
  .middle {
    width: 200px;
    position: absolute;
    right: 170px;
    top: 10px;
  }
  .right {
    position: absolute;
    right: 20px;
    top: 10px;
  }
  .book-title {
    line-height: 30px;
    font-size: 24px;
    font-weight: 600;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.qx-iframe {
  border: none;
  padding: 0px;
  margin: 0px;
  overflow: hidden auto;
  width: 100%;
  height: calc(100vh - 50px);
}

.canvas-container {
  width: 100%;
  overflow: hidden;
}

.pdf-container {
  width: 100%;
}
.el-drawer__body {
  padding: 0 22px;
}
.little-tab .book-title {
  line-height: 30px;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
}
.bookshelf .image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 14px;
}
.upload-class .el-upload-list {
  display: none;
}
.el-form--inline .el-form-item {
  margin-right: 0;
}
</style>
