// store/index.js

import { createStore } from 'vuex';

export default createStore({
  state: {
    permissionBtn: false,
    equipModel:true,    //true表示不在微信或者钉钉中打开,需要展示标题，
    range:'',
  },
  mutations: {
    increment(state) {
      state.permissionBtn = true
    },
    setModel(state,val) {
      state.equipModel = val.extraData
    },
    setRange(state,val) {
      state.range = val.extraData
    },
  },

  actions: {
    increment(context) {
      context.commit('increment');
    },
    setModel(context,payload) {
      context.commit('setModel',payload);
    },
    setRange(context,payload) {
      console.log(context,payload,'传值');
      context.commit('setRange',payload);
    },
  },
  getters: {
    getCount(state) {
      return state.permissionBtn;
    },
    getModel(state){
      return state.equipModel;
    },
    getRange(state){
      return state.range;
    }
  }
});
