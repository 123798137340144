<template>
  <!-- <HelloWorld/> -->
  <router-view />
</template>

<script>
import { onMounted } from "vue";

export default {
  name: "App",
  mounted() {
    const equipModel = navigator.userAgent;
    if(/MicroMessenger/i.test(equipModel) || /DingTalk/i.test(equipModel)){
      this.$store.dispatch('setModel',{ extraData: false });
    }else{
      this.$store.dispatch('setModel',{ extraData: true });
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 30px; */
}
</style>
